import React from 'react';
import bkg from '../../../assets/img/bkg.jpg';
import bkg2 from '../../../assets/img/bkg2.jpg';
import styled from 'styled-components';

const Parallax = styled.div`
  background-image: url(${bkg});
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  /* min-height: 400px;
  position: relative;
  opacity: 0.65;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
`;

const Parallax2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(${bkg2});
  height: 90vh;
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const Caption = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  text-align: center;
  color: #000;
`;

const Title = styled.span`
  background-color: #111;
  color: #fff;
  padding: 18px;
  font-size: 25px;
  letter-spacing: 10px;
`;

const Divider = styled.div`
  color: #777;
  background-color: white;
  text-align: center;
  padding: 50px 80px;
  text-align: justify;
`;

const DividerTitle = styled.h3`
  text-align: center;
`;

const Home = () => {
  return (
    <div style={{ height: '100%' }}>
      <Parallax>
        <Caption>
          <Title>The #1 app for runners and cyclists</Title>
        </Caption>
      </Parallax>

      <Divider>
        <DividerTitle>Parallax Demo</DividerTitle>
        <p>
          Parallax scrolling is a web site trend where the background content is moved at a
          different speed than the foreground content while scrolling. Nascetur per nec posuere
          turpis, lectus nec libero turpis nunc at, sed posuere mollis ullamcorper libero ante
          lectus, blandit pellentesque a, magna turpis est sapien duis blandit dignissim. Viverra
          interdum mi magna mi, morbi sociis. Condimentum dui ipsum consequat morbi, curabitur
          aliquam pede, nullam vitae eu placerat eget et vehicula. Varius quisque non molestie
          dolor, nunc nisl dapibus vestibulum at, sodales tincidunt mauris ullamcorper, dapibus
          pulvinar, in in neque risus odio. Accumsan fringilla vulputate at quibusdam sociis
          eleifend, aenean maecenas vulputate, non id vehicula lorem mattis, ratione interdum sociis
          ornare. Suscipit proin magna cras vel, non sit platea sit, maecenas ante augue etiam
          maecenas, porta porttitor placerat leo.
        </p>
      </Divider>

      <Parallax2>
        <Caption>
          <Title>The #1 app for runners and cyclists</Title>
        </Caption>
      </Parallax2>

      <Divider>
        <DividerTitle>Parallax Demo</DividerTitle>
        <p>
          Parallax scrolling is a web site trend where the background content is moved at a
          different speed than the foreground content while scrolling. Nascetur per nec posuere
          turpis, lectus nec libero turpis nunc at, sed posuere mollis ullamcorper libero ante
          lectus, blandit pellentesque a, magna turpis est sapien duis blandit dignissim. Viverra
          interdum mi magna mi, morbi sociis. Condimentum dui ipsum consequat morbi, curabitur
          aliquam pede, nullam vitae eu placerat eget et vehicula. Varius quisque non molestie
          dolor, nunc nisl dapibus vestibulum at, sodales tincidunt mauris ullamcorper, dapibus
          pulvinar, in in neque risus odio. Accumsan fringilla vulputate at quibusdam sociis
          eleifend, aenean maecenas vulputate, non id vehicula lorem mattis, ratione interdum sociis
          ornare. Suscipit proin magna cras vel, non sit platea sit, maecenas ante augue etiam
          maecenas, porta porttitor placerat leo.
        </p>
      </Divider>
      {/* 
      <div class="bgimg-2">
        <div class="caption">
          <span class="border" style="background-color:transparent;font-size:25px;color: #f7f7f7;">
            LESS HEIGHT
          </span>
        </div>
      </div>

      <div style="position:relative;">
        <div style="color:#ddd;background-color:#282E34;text-align:center;padding:50px 80px;text-align: justify;">
          <p>Scroll up and down to really get the feeling of how Parallax Scrolling works.</p>
        </div>
      </div>

      <div class="bgimg-3">
        <div class="caption">
          <span class="border" style="background-color:transparent;font-size:25px;color: #f7f7f7;">
            SCROLL UP
          </span>
        </div>
      </div>

      <div style="position:relative;">
        <div style="color:#ddd;background-color:#282E34;text-align:center;padding:50px 80px;text-align: justify;">
          <p>Scroll up and down to really get the feeling of how Parallax Scrolling works.</p>
        </div>
      </div>

      <div class="bgimg-1">
        <div class="caption">
          <span class="border">COOL!</span>
        </div>
      </div> */}

      {/* <img
        src={bkg}
        style={{
		  position: 'absolutte',
		  
        }}
      /> */}
    </div>
  );
};

export default Home;
