import React, { useEffect, useState } from 'react';
import { GiRunningShoe, GiWeightLiftingUp, GiEgyptianWalk } from 'react-icons/gi';
import { FaSkiingNordic } from 'react-icons/fa';
import { INTENSITY_RGB } from '../../config/constants/constants';

const WorkoutBar = ({ workout, x, maxHeight, barWidth, barMargin, magnify }) => {
  //   const x = index * (barWidth + barMargin);
  const { progress, type, schedule } = workout;
  let currentY = maxHeight;
  const totalBarHeight = parseFloat(workout.estimatedDistance.$numberDecimal) * magnify;
  const barHeightPerSecond = totalBarHeight / parseFloat(workout.estimatedDuration.$numberDecimal);
  let bars = schedule.map(({ time, _id, speed }) => {
    let barHeight = time * barHeightPerSecond;
    currentY -= barHeight;
    return (
      <Bar
        key={_id}
        x={x}
        width={barWidth}
        y={currentY}
        height={barHeight}
        progress={0}
        type={type}
        speed={speed ?? 'EASY'}
        percentCompleted={null}
        //   expenseName={data.name}
        //   highestExpense={highestExpense}
      />
    );
  });
  return (
    <>
      {bars}
      <WorkoutIcon x={x} y={currentY} width={barWidth} sport={type} />
    </>
  );
};

const Bar = ({ x, y, width, height, progress, speed, percentCompleted }) => (
  <>
    {progress < height ? (
      <rect x={x} y={y} width={width} height={height} fill={`rgba(${INTENSITY_RGB[speed]}, 0.8)`} />
    ) : null}
    <rect
      x={x}
      y={progress < height ? y - (progress - height) : y}
      width={width}
      height={progress}
      fill={`rgba(${INTENSITY_RGB[speed]})`}
    />
    {percentCompleted ? (
      <text
        x={x + width / 4}
        y={(progress < height ? y - (progress - height) : y) + 17}
        fontSize="10"
        fill="rgb(256,256,256)"
      >
        {percentCompleted}%
      </text>
    ) : null}
  </>
);

const WorkoutIcon = ({ x, y, width, sport }) => {
  switch (sport) {
    case 'Run':
      return <GiRunningShoe x={x + width / 3} y={y - 20} />;
    case 'Strength':
      return <GiWeightLiftingUp x={x + width / 3} y={y - 20} style={{ color: '#F0A868' }} />;
    case 'NordicSki':
      return <FaSkiingNordic x={x + width / 3} y={y - 20} style={{ color: 'rgb(77,114,152)' }} />;
    case 'Walk':
    default:
      return <GiEgyptianWalk x={x + width / 3} y={y - 20} style={{ color: '#F0A868' }} />;
  }
};

export default WorkoutBar;

// Calculate highest expense for the month
//   const calculateHighestExpense = data =>
//     data.reduce((acc, cur) => {
//       const { expense } = cur;
//       return expense > acc ? expense : acc;
//     }, 0);
//   const [highestExpense, setHighestExpense] = useState(calculateHighestExpense(data));
