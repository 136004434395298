import React from 'react';
import Container from '@material-ui/core/Container';
import ProgressOnboarding from './ProgressOnboarding';
import Grid from '@material-ui/core/Grid';

const OnboardingBoxWithProgress = ({ children, step, setTabIndex, completedSteps }) => {
  return (
    <Container
      sx={{
        paddingTop: '100px',
      }}
    >
      <ProgressOnboarding step={step} setTabIndex={setTabIndex} completedSteps={completedSteps} />
      <Grid
        sx={{
          paddingTop: '50px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {children}
      </Grid>
    </Container>
  );
};

export default OnboardingBoxWithProgress;
