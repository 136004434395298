import { createAction } from 'redux-actions';
import trenAPI from '../../services/tren/tren';
import { setLoading } from './loaderAction';

export const resetConnection = createAction('CONNECT/RESET');
export const setConnection = createAction('CONNECT/SET');

export const reciveCallback = params => async dispatch => {
  try {
    dispatch(setLoading(true));
    const userData = await trenAPI.saveConnection(params);
    dispatch(setConnection(userData));
    trenAPI.getNewActivities().then(res => {
      trenAPI.analyzeBackground().then(res => dispatch(setLoading(false)));
    });
  } catch (err) {
    dispatch(setLoading(false));
    console.error(err);
  }
};

export const getConnectionServices = () => async dispatch => {
  try {
    await trenAPI.getConnectionServices().then(connections => dispatch(setConnection(connections)));
  } catch (err) {
    console.error(err);
  }
};
