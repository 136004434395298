import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ children, component: RouteComponent, ...rest }) => {
  const mapStateToProps = ({ user: { uid, inited, status: { hasPlan } = {} } = {} }) => ({
    inited,
    uid,
    hasPlan,
  });
  const { inited, uid, hasPlan } = useSelector(mapStateToProps);
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (inited) {
          if (!uid) {
            return (
              <Redirect
                to={{
                  pathname: '/app',
                  state: { from: location },
                }}
              />
            );
          }
          if (hasPlan || location.pathname.includes('onboarding')) {
            return (RouteComponent && <RouteComponent />) || children;
          } else {
            return (
              <Redirect
                to={{
                  pathname: '/onboarding',
                  state: { from: location },
                }}
              />
            );
          }
        }
        return <div />;
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType,
};

export default PrivateRoute;
