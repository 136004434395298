import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import TextFieldWithController from '../textField/TextFieldWithController';
import RadioWithController from '../radioButton/RadioWithController';

const PersonaliaFields = ({ control, errors, watch }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading, userHeight, userWeight, sport, inited } = useSelector(
    ({ loader: { loading }, onboarding: { userHeight, userWeight, inited, sport } }) => ({
      inited,
      loading,
      userHeight,
      userWeight,
      sport,
    })
  );

  return inited ? (
    <>
      <TextFieldWithController
        name={'userHeight'}
        label={'How tall are you?'}
        control={control}
        value={userHeight ?? null}
        // value={}
        rules={{
          type: 'number',
          required: true,
        }}
        errors={errors}
      />
      <TextFieldWithController
        name={'userWeight'}
        label={'What is your weight?'}
        control={control}
        value={userWeight ?? null}
        // value={}
        rules={{
          type: 'number',
          required: true,
        }}
        errors={errors}
      />
      <RadioWithController
        name={'sport'}
        label={'What sport do you do?'}
        control={control}
        value={sport ? 'running' : null}
        rules={{
          required: true,
        }}
        options={[
          {
            title: 'Running',
            value: 'running',
          },
          {
            title: 'Cross country skiing',
            value: 'crosscountry',
          },
          {
            title: 'Football',
            value: 'football',
          },
        ]}
        errors={errors}
      />
    </>
  ) : null;
};

export default PersonaliaFields;
