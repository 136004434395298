import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  padding: 5px;
`;
const Circle = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 10px;
  margin: 0 10px;
  ${props =>
    props.color &&
    css`
      background-color: ${props.color};
    `}
`;

const Label = styled.span`
  font-size: 22px;
  line-height: 22px;
`;

const Legend = ({ color, title }) => {
  return (
    <Container>
      <div>
        <Circle color={color} />
      </div>
      <Label>{title}</Label>
    </Container>
  );
};

Legend.propTypes = {
  label: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number,
};
export default Legend;
