import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useHistory,
  useRouteMatch,
  useLocation,
} from 'react-router-dom';

import Home from '../Root/home/Home';
import PrivacyPolicy from '../Root/privacyPolicy/PrivacyPolicy';
import LandingPage from '../Root/landingPage/LandingPage';
import Login from '../Root/auth/Login';
import Logout from '../Root/auth/Logout';
import NotFound from '../Root/notFound/NotFound';
import { auth } from '../../services/firebase/firebase';
import { authStateChange, logInUser } from '../../store/actions/userAction';
import usePageTracking from './usePageTracking';
import DashboardSwitch from '../DashboardSwitch/Dashboard.switch';
import PrivateRoute from './PrivateRoute';
import OnboardingConnect from '../OnbardingConnect/OnboardingConnect.page';
import OnbardingWizard from '../OnbardingWizard/OnbardingWizard.page';

const RootSwitch = () => {
  usePageTracking();
  let history = useHistory();
  let { path } = useRouteMatch();
  let location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = auth().onAuthStateChanged(user => dispatch(authStateChange(user)));
    return () => unsubscribe();
  }, [authStateChange]);

  //   useEffect(() => {
  //     if (location.pathname === '/') {
  //       console.log('hello');
  //       if (user) {
  //         console.log(`${path}/dashboard`);
  //         history.push(`${path}/dashboard`);
  //       } else {
  //         console.log(`${path}/app`);
  //         history.push(`/app`);
  //       }
  //     }
  //   }, [path, location]);

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
        <PrivateRoute path="/dashboard">
          <DashboardSwitch />
        </PrivateRoute>

        <PrivateRoute path="/onboarding/connect" component={OnboardingConnect} />
        <PrivateRoute path="/onboarding" component={OnbardingWizard} />

        <Route exact path="/home" component={Home} />
        <Route exact path="/app" component={LandingPage} />
        <Route exact path="/privacypolicy" component={PrivacyPolicy} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/logout" component={Logout} />
        <Route exact path="/not-found" component={NotFound} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default RootSwitch;
