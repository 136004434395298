import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getCurrentWeekDays } from '../../store/actions/weekPlanAction';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Box from '@mui/material/Box';
import Typography from '@material-ui/core/Typography';
import { INTENSITY_RGB } from '../../config/constants/constants';
moment.locale('nb');
require('moment/locale/nb.js');

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const PADDING = 40;
const WEEKDAY = 120;

const Dashboard = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [hours, setHours] = useState([]);
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [loading, setLoading] = useState(true);
  const [pixelsPerHour, setPixelsPerHour] = useState(true);
  const [pixelsPerBar, setPixelsPerBar] = useState(true);
  const [startDay, setStartDay] = useState(moment());
  const [endDay, setEndDay] = useState(moment().add(7, 'days'));
  const [showDays, setShowDays] = useState([]);

  const [totalLoad, setTotalLoad] = useState(null);
  const [loadChart, setLoadChart] = useState(null);
  const [maxLoad, setMaxLoad] = useState(null);
  const [chartWidth, setChartWidth] = useState(null);
  const mapStateToProps = ({ weekPlan: { days } }) => days;
  const days = useSelector(mapStateToProps);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCurrentWeekDays());
  }, []);

  useLayoutEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (days && windowDimensions) {
      const startOfDay = moment().startOf('day').set('hours', 16);
      setStartTime(14);
      const endOfDay = moment().startOf('day').set('hours', 22);
      const hoursBetween = endOfDay.diff(startOfDay, 'hours');
      const pixelsAvailable = windowDimensions.width - (PADDING * 2 + WEEKDAY);
      const pixelsPerHourTemp = pixelsAvailable / hoursBetween;
      setPixelsPerBar(pixelsAvailable);
      let hoursArray = [];
      while (startOfDay.isBefore(endOfDay)) {
        hoursArray.push(startOfDay.format('HH:SS'));
        startOfDay.add(1, 'hour');
      }
      setHours(hoursArray);
      setPixelsPerHour(pixelsPerHourTemp);
      setEndTime(endOfDay);
      setLoading(false);
    }
  }, [days, windowDimensions]);

  console.log({
    startTime,
    endTime,
    hours,
    days,
  });
  return (
    <Box sx={{ paddingLeft: `${PADDING}px`, paddingRight: `${PADDING}px` }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {loading ? (
          <>
            <h3>Generating new plan...</h3>
          </>
        ) : (
          <Box>
            <Box
              sx={{
                position: 'relative',
              }}
            >
              <Typography variant="h6">WEEKDAY</Typography>
              {hours.map((hour, index) => (
                <Typography
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: `${WEEKDAY + index * pixelsPerHour}px`,
                  }}
                  variant="h6"
                >
                  {hour}
                </Typography>
              ))}
            </Box>
            {Object.values(days).map(({ title, date, workouts }) => (
              <Box
                sx={{
                  position: 'relative',
                  paddingTop: '20px',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Typography variant="h6">{title}</Typography>
                <Box
                  sx={{
                    position: 'absolute',
                    top: 20,
                    left: `${WEEKDAY}px`,
                    width: `${pixelsPerBar}px`,
                    height: `40px`,
                    border: '1px solid #CCC',
                    '&:hover': {
                      background: 'rgb(7, 177, 77, 0.42)',
                    },
                  }}
                >
                  {workouts.map(workout => {
                    console.log({ workout });
                    const date = moment(workout.date);
                    const hours = date.hours();
                    const duration = workout.estimatedDuration.$numberDecimal / 60 / 60;
                    const diffHours = hours - startTime;
                    const startLeft = pixelsPerHour * diffHours;
                    const width = Math.floor(pixelsPerHour * duration);
                    const color = INTENSITY_RGB?.[workout.workoutType] ?? INTENSITY_RGB?.['EASY'];
                    return (
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: `${startLeft}px`,
                          width: `${width}px`,
                          height: `40px`,
                          backgroundColor: `rgb(${color})`,
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{
                            fontSize: '12px',
                            paddingTop: '10px',
                            paddingLeft: '10px',
                          }}
                        >
                          {workout.workoutType} -{' '}
                          {Math.round(workout.estimatedDistance.$numberDecimal)}km
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Dashboard;
