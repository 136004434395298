import { createAction } from 'redux-actions';
import tren from '../../services/tren/tren';
import moment from 'moment';

export const setWeek = createAction('WEEKPLAN/SET');

export const getCurrentWeek = () => dispatch =>
  tren
    .getCurrentWeek()
    .then(data => {
      dispatch(
        setWeek({
          ...data,
          workouts: data.workouts
            .filter(({ type }) => type === 'Run' || type === 'NordicSki')
            .reduce((acc, cur, index) => {
              const weekday = moment(cur.date).isoWeekday();
              const workout = { ...cur, workoutIndex: index };
              acc[weekday] = [...(acc[weekday] ? [...acc[weekday], workout] : [workout])];
              acc[weekday] = acc[weekday].sort((a, b) =>
                moment(a.date).isBefore(moment(b.date)) ? -1 : 1
              );
              return acc;
            }, {}),
        })
      );
    })
    .catch(() => console.warn('failed to get user data'));

export const getCurrentWeekDays = () => dispatch => {
  const startDay = moment().startOf('week');
  const endDay = startDay.clone().add(7, 'days');
  const days = {};
  while (startDay.isBefore(endDay)) {
    days[startDay.format('YYYYMMDD')] = {
      title: startDay.format('dddd'),
      date: startDay,
      workouts: [],
    };
    startDay.add(1, 'day');
  }
  tren
    .getCurrentWeek()
    .then(data => {
      data.workouts
        .filter(({ type }) => type === 'Run' || type === 'NordicSki')
        .forEach((workout, index) => {
          const weekday = moment(workout.date).format('YYYYMMDD');
          days[weekday].workouts.push({ ...workout, workoutIndex: index });
        });
      dispatch(
        setWeek({
          ...data,
          days,
        })
      );
    })
    .catch(() => console.warn('failed to get user data'));
};
