import i18n from "i18n-js";
import { getLang } from "../configLocale";

import en from "../languages/en.json";
import no from "../languages/no.json";


i18n.locale = getLang();
i18n.fallbacks = true;
i18n.translations = {en,no};

export function strings(name) {
  return i18n.t(name);
}

export default i18n;