import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getCurrentWeek } from '../../store/actions/weekPlanAction';
import WorkoutBar from './WorkoutBar';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Legend from '../../components/charts/label/Legend';
import { INTENSITY_RGB } from '../../config/constants/constants';
moment.locale('nb');
require('moment/locale/nb.js');

const BAR_WIDTH = 50;
const BAR_MARGIN = 30;
const MAGNIFY = 10;
const DAY_MARGIN = 10;

const LegendContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 30px;
  margin-bottom: 30px;
`;

const Dashboard = () => {
  const [charts, setCharts] = useState(null);
  const [max, setMax] = useState(null);
  const [totalLoad, setTotalLoad] = useState(null);
  const [loadChart, setLoadChart] = useState(null);
  const [maxLoad, setMaxLoad] = useState(null);
  const [chartWidth, setChartWidth] = useState(null);
  const mapStateToProps = ({ weekPlan: { workouts } }) => workouts;
  const workouts = useSelector(mapStateToProps);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCurrentWeek());
  }, [dispatch]);

  useEffect(() => {
    if (!max && workouts) {
      //   workouts.filter(w => !w.progress.length).map(w => console.log({ w }));
      //   workouts
      //     .filter(w => w.progress.length)
      //     .filter(w => !w.progress[0].duration)
      //     .map(w => console.log({ p: w.progress }));
      const maxDistance =
        Object.values(workouts)
          .flat()
          .reduce((acc, cur) => {
            const planned = cur.progress?.[0]?.duration ?? 0;
            const completed = (cur.progress?.[0]?.completed ?? 0) / 1000;
            const highest = planned > completed ? planned : completed;
            return acc > highest ? acc : highest;
          }, 0) * 10;
      setMax(maxDistance);
      const stressScores = Object.values(workouts)
        .flat()
        .map(({ stressScore: { $numberDecimal } }) => parseFloat($numberDecimal));
      setTotalLoad(stressScores.reduce((acc, cur) => acc + cur));
      const maxLoadTemp = stressScores.reduce((acc, cur) => (acc > cur ? acc : cur), 0);
      setMaxLoad(maxLoadTemp);
      let heightPerLoadPoint = maxDistance / maxLoadTemp;
      const loadPoints = stressScores
        .map(
          (stressScore, index) =>
            `${index * (BAR_WIDTH + BAR_MARGIN) + index * DAY_MARGIN * 2},${
              maxDistance - (stressScore * heightPerLoadPoint - 20)
            }`
        )
        .flat()
        .join('\n');
      setLoadChart(loadPoints);
      setChartWidth(
        Object.values(workouts).flat().length * (BAR_WIDTH + BAR_MARGIN) + 7 * DAY_MARGIN
      );
    } else if (max) {
    }
  }, [workouts, max]);
  return (
    <Grid container>
      <Grid item xs={10}>
        {max ? (
          <>
            <LegendContainer>
              <div>
                <Legend color={`rgb(${INTENSITY_RGB['EASY']})`} title={'Easy run'} />
                <Legend color={`rgb(${INTENSITY_RGB['THRESHOLD']})`} title={'Threshold'} />
                <Legend color={`rgb(${INTENSITY_RGB['1MILE']})`} title={'Repeat pace'} />
                <Legend color={`rgb(${INTENSITY_RGB['BREAK']})`} title={'Pause'} />
              </div>
              <div>
                <Legend color={`rgba(${INTENSITY_RGB['EASY']},0.4)`} title={'Planned easy run'} />
                <Legend
                  color={`rgb(${INTENSITY_RGB['THRESHOLD']},0.4)`}
                  title={'Planned threshold'}
                />
                <Legend
                  color={`rgb(${INTENSITY_RGB['1MILE']},0.4)`}
                  title={'Planned repeat pace'}
                />
              </div>
            </LegendContainer>
            <Chart height={max + 20} width={chartWidth}>
              {Object.values(workouts).map((day, index) => {
                const dayLength = day.length;
                const workoutIndex = day?.[0]?.workoutIndex ?? index;
                const x = workoutIndex * (BAR_WIDTH + BAR_MARGIN) + (index - 1) * DAY_MARGIN * 2;

                return (
                  <Day
                    key={`chart-${index}`}
                    x={x - DAY_MARGIN / 2}
                    height={max + 50}
                    width={dayLength * (BAR_WIDTH + BAR_MARGIN)}
                    day={index}
                    dayLength={dayLength}
                  >
                    {day.map((workout, workoutIndex) => (
                      <WorkoutBar
                        key={workout._id}
                        maxHeight={max + 20}
                        workout={workout}
                        x={x + workoutIndex * (BAR_WIDTH + BAR_MARGIN)}
                        barWidth={BAR_WIDTH}
                        barMargin={BAR_MARGIN}
                        magnify={MAGNIFY}
                      />
                    ))}
                  </Day>
                );
              })}
              <polyline fill="none" stroke="#0074d9" stroke-width="3" points={loadChart} />
            </Chart>
          </>
        ) : null}
      </Grid>
    </Grid>
  );
};

const Chart = ({ children, width, height }) => (
  <svg
    viewBox={`0 0 ${width} ${height + 50}`}
    width="100%"
    height="70%"
    preserveAspectRatio="xMidYMax meet"
    // style={{ backgroundColor: '#f8f9fa' }}
  >
    {children}
    {/* <path
      stroke-dasharray="2,2"
      d={`M-100 ${height} L${width} ${height}`}
      stroke="grey"
      stroke-width="1"
    /> */}
  </svg>
);

const Day = ({ children, height, x, width, day, dayLength }) => (
  <>
    <path
      strokeDasharray="2,2"
      d={`M${width + x} 0 L${width + x} ${height}`}
      stroke="grey"
      strokeWidth="1"
    />
    <line x1={width + x} y1={0} x2={width + x} y2={height} strokeDasharray={5} strokeWidth="1" />
    <text x={x + (dayLength > 1 ? width / (dayLength + 1) : 15)} y={height} fontSize="10">
      {moment().weekday(day).format('dddd').toLocaleUpperCase()}
    </text>
    {children}
  </>
);

export default Dashboard;

// Calculate highest expense for the month
//   const calculateHighestExpense = data =>
//     data.reduce((acc, cur) => {
//       const { expense } = cur;
//       return expense > acc ? expense : acc;
//     }, 0);
//   const [highestExpense, setHighestExpense] = useState(calculateHighestExpense(data));
