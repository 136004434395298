import { createAction } from 'redux-actions';
import tren from '../../services/tren/tren';
import axios from 'axios';

export const setData = createAction('ANALYZE/SET');

export const getAnalytics = () => dispatch =>
  tren
    .getAnalytics()
    .then(data => {
      dispatch(setData(data));
    })
    .catch(() => console.warn('failed to get user data'));
