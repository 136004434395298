import React from 'react';
import styled from 'styled-components';
import Checkbox from './Checkbox';

const Container = styled.button`
  :hover {
    background-color: #fff;
    border: ${props => (props.checked ? '2px solid #446aff' : 'none')};
  }
  :active {
    box-shadow: none !important;
    background-color: #fff !important;
  }
  height: 150px;
  width: '100%';
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border: ${props => (props.checked ? '2px solid #446aff' : 'none')};
  background-color: #fff;
`;
const Row = styled.div`
  /* display: flex;
  justify-content: space-between;
  width: 100%; */
`;

const CheckboxButton = ({ checked, onClick, onChange = () => {}, img }) => {
  return (
    <Container type="button" className="btn btn-secondary" onClick={onClick} checked={checked}>
      <Row>
        <p>Connected to</p>
        <Checkbox checked={checked} onChange={onChange} />
      </Row>
      <Row>
        <img src={img} width={100} height={50} alt="img" />
      </Row>
    </Container>
  );
};

export default CheckboxButton;
