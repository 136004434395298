import { handleActions } from 'redux-actions';
import { setData } from '../actions/analyzeAction';

const initialState = {
  //   zones: null,
  //   distance: null,
};

export default handleActions(
  {
    [setData]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
  initialState
);
