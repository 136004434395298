import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled from 'styled-components';
import { getAllWeeks } from '../../store/actions/planAction';
import moment from 'moment';

const Column = styled.div`
  border-bottom: 1px solid grey;
`;
const DayContainer = styled.div`
  border: 1px solid grey;
`;
const DayMarkerContainer = styled.div`
  width: 100%;
  display: flex;
`;
const DateMarker = styled.div`
  align-self: flex-end;
`;
const WorkoutContainer = styled.div``;

const WORKOUTS = {
  SPEED: 'Speed',
  REPEAT: 'Repeat',
  INTERVALL: 'Intervall',
  THRESHOLD: 'Threshold',
  THRESHOLD_AND_REPEAT: 'T & R',
  MARATHON: 'Marathon',
  LONG: 'Long',
  EASY: 'Easy',
};

const PlanOverview = () => {
  const [charts, setCharts] = useState(null);
  const [max, setMax] = useState(null);
  const [chartWidth, setChartWidth] = useState(null);
  const mapStateToProps = ({ plan: { weeks } }) => weeks;
  const weeks = useSelector(mapStateToProps);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllWeeks());
  }, [dispatch]);

  const infoBlock = (weeknr, milage) => {
    return (
      <div>
        <p>Weeknr: {weeknr}</p>
        <p>Milage: {milage}</p>
      </div>
    );
  };

  const createRowFromWeek = ({ workouts, weekNr, milage }) => {
    let weeksDate = moment('2021').add(weekNr, 'weeks').startOf('week');
    const startDate = weeksDate.clone();
    const res = [...Array(7)].map(() => {
      weeksDate = weeksDate.add(1, 'days');
      return {
        workouts: workouts.filter(({ date }) => weeksDate.isSame(date, 'day')),
        day: weeksDate.date(),
      };
    });
    const endDate = weeksDate.clone();

    return (
      <TableRow>
        <TableCell>{infoBlock(weekNr, milage)}</TableCell>
        {res.map(({ workouts, day }) => {
          return (
            <TableCell
              xs={1}
              className="col"
              sx={{
                border: '1px solid grey',
              }}
            >
              <DayMarkerContainer>
                <DateMarker>{day}</DateMarker>
              </DayMarkerContainer>
              {workouts.map(({ workoutType, estimatedDistance }) => {
                return (
                  <div>
                    {WORKOUTS[workoutType]} -{' '}
                    {Math.round(parseFloat(estimatedDistance.$numberDecimal))}km
                  </div>
                );
              })}
              <WorkoutContainer></WorkoutContainer>
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  return (
    <div className="container">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead></TableHead>
          <TableBody>{weeks && weeks.map(createRowFromWeek)}</TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PlanOverview;
