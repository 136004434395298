import React, { useEffect } from 'react';
import { auth } from '../../../services/firebase/firebase';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logInUser } from '../../../store/actions/userAction';

const Login = () => {
  let history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    auth().signOut();
    dispatch(logInUser(null));
    history.push('/login');
  }, [dispatch, history]);

  return <div className="contianer"></div>;
};

export default Login;
