import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from './store/reducers/configureStore';
import RootSwitch from './pages/RootSwitch/Root.switch';

if (
  !(
    window.location.host.startsWith('127.0.0.1') ||
    window.location.host.startsWith('localhost') ||
    window.location.host.startsWith('stage')
  ) &&
  window.location.protocol !== 'https:'
)
  window.location.protocol = 'https:';

ReactDOM.render(
  <Provider store={store()}>
    <BrowserRouter>
      {/* <AuthProvider> */}
      <RootSwitch />
      {/* </AuthProvider> */}
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
