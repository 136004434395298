import axios from 'axios';
import api from './endpoints';

const changeUrlKeys = (url, urlKeys = {}) => {
  const keys = Object.keys(urlKeys).map(key => ({ urlKey: `:${key}`, value: urlKeys[key] }));

  return keys.reduce((url, { urlKey, value }) => url.replace(urlKey, value), url);
};

const endpoints = {};
Object.keys(api).forEach(key => {
  const { url, method } = api[key];
  endpoints[key] = ({ urlKeys, ...args } = {}) => {
    return axios[method](changeUrlKeys(url, urlKeys), args)
      .catch(err => {
        console.error({ err });
        // throw err;
      })
      .then(({ data }) => data)
      .catch(err => {
        console.error(err);
        return null;
      });
  };
});

export default endpoints;
