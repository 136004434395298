import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Nav from '../../components/nav/DashboardNav';
import Dashboard from '../Dashboard/Dashboard.page';
import Dashboard2 from '../Dashboard2/Dashboard2.page';
import PlanOverview from '../PlanOverview/PlanOverview.page';
import PlanSettingsTabs from '../PlanSettings/PlanSettings.page';

const DashboardSwitch = () => {
  let { path } = useRouteMatch();
  return (
    <>
      <Nav />
      <Switch>
        <Route exact path={path} component={Dashboard} />
        <Route exact path={`${path}/dashboard2`} component={Dashboard2} />
        <Route exact path={`${path}/plan`} component={PlanOverview} />
        <Route exact path={`${path}/settings`} component={PlanSettingsTabs} />
      </Switch>
    </>
  );
};

export default DashboardSwitch;
