import React from 'react';
import { Controller } from 'react-hook-form';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';

const RadioWithController = ({ control, name, label, errors, value, rules, options }) => (
  <FormControl error={!!errors?.[name]}>
    <FormLabel id={`radio-label-${name}`} component="legend">
      {label}
    </FormLabel>
    <Controller
      name={name}
      control={control}
      defaultValue={value}
      rules={rules}
      render={({ field }) => (
        <>
          <RadioGroup
            id={`question-select-${name}`}
            aria-label="gender"
            defaultValue="female"
            name="radio-buttons-group"
            {...field}
          >
            {options.map(option => (
              <FormControlLabel
                key={option.title}
                value={option.value}
                control={<Radio />}
                label={option.title}
              />
            ))}
          </RadioGroup>
          {!!errors?.[name] ? <FormHelperText>{errors?.[name].message}</FormHelperText> : null}
        </>
      )}
    />
  </FormControl>
);

export default RadioWithController;
