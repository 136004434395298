import React from 'react';
import lang from '../../../translationWrapper';

const Page404 = () => (
  <div className="ui center aligned text container">
    <h1>{lang.notFound.pageNotFound}</h1>
  </div>
);

export default Page404;
