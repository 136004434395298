import { handleActions } from 'redux-actions';
import { setWeek } from '../actions/weekPlanAction';

const initialState = {
  milage: 0,
  phase: '',
  days: [],
};

export default handleActions(
  {
    [setWeek]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
  initialState
);
