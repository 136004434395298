import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Stack from '@material-ui/core/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import { saveUserStatus } from '../../store/actions/userAction';
import Dashboard from '../Dashboard/Dashboard.page';
import { useHistory } from 'react-router';

const OnbardingPreview = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { loading } = useSelector(({ loader: { loading } }) => ({
    loading,
  }));

  const onSubmit = () => {
    dispatch(saveUserStatus({ hasPlan: true }));
    history.push('/dashboard');
  };

  return (
    <>
      <Dashboard />
      <Stack direction="row" spacing={2} sx={{ paddingTop: '200px' }}>
        <LoadingButton loading={loading} variant="contained" onClick={onSubmit}>
          Looks good!
        </LoadingButton>
      </Stack>
    </>
  );
};

export default OnbardingPreview;
