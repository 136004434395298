import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Stack from '@material-ui/core/Stack';
import ConnectServicesFields from '../../components/forms/ConnectServiceFields';
import { saveOnboardingData } from '../../store/actions/onboardingAction';

const OnbardingConnectServices = ({ next }) => {
  const dispatch = useDispatch();
  const { strava, garmin, loading } = useSelector(
    ({ connect: { strava, garmin }, loader: { loading } }) => ({ strava, garmin })
  );

  const onSubmit = () => {
    dispatch(
      saveOnboardingData({
        connectDone: true,
        onboardingProgress: 'personlia',
      })
    );
    next();
  };

  return (
    <Grid
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ConnectServicesFields />
      <Grid
        sx={{
          paddingTop: '60px',
        }}
      >
        {loading ? (
          <h3>Generating awesomeness...</h3>
        ) : strava || garmin ? (
          <Stack direction="row" spacing={2}>
            <Button variant="contained" onClick={onSubmit}>
              Continue
            </Button>
          </Stack>
        ) : (
          <Stack direction="row" spacing={2}>
            <Button variant="contained" onClick={onSubmit}>
              No activity tracker
            </Button>
          </Stack>
        )}
      </Grid>
    </Grid>
  );
};

export default OnbardingConnectServices;
