export const ADD_PHONE_NUMBER_TO_PORTAL_ACCESS_MODAL = 'addPhoneNumberToPortalAccessModal';

export const STATUS_CODE_SERVER_ERROR = 500;
export const STATUS_CODE_USER_DATA_IS_INVALID = 400;
export const PREGNANCY_MODULE = 'pregnancyAndBirth';
export const PARENT_MODULE = 'parent';

export const INTENSITY_RGB = {
  //   ['EASY']: '137,178,90',
  //   ['1MILE']: '240, 168, 104',
  //   ['THRESHOLD']: '77,114,152',
  //   ['MARATHON']: '76, 30, 79',
  //   ['BREAK']: '243, 249, 227',
  ['EASY']: '161, 195, 73',
  ['MARATHON']: '243, 192, 83',
  ['THRESHOLD']: '249, 160, 63',
  ['1MILE']: '235, 94, 40',
  ['BREAK']: '243, 249, 227',
};
