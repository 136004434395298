import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Container } from '@material-ui/core';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabPanel from '../../components/tabs/TabPanel';
import RunningSettings from './RunningSettings';
import ConnectServicesFields from '../../components/forms/ConnectServiceFields';

const PlanSettingsTabs = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [value, setValue] = useState(0);

  const a11yProps = index => ({
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  });
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container>
      <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs"
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          <Tab label="Running background" {...a11yProps(0)} />
          <Tab label="Services" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <RunningSettings />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ConnectServicesFields />
        </TabPanel>
      </Box>
    </Container>
  );
};

export default PlanSettingsTabs;
