import React from 'react';
import { useHistory, useLocation, Link as ReactRouterLink } from 'react-router-dom';
import trailrun from '../../../assets/img/trailrun.jpg';
import bkg from '../../../assets/img/bkg.jpg';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import lang from '../../../translationWrapper';

const Container = styled.div`
  position: absolute;
  top: 0px;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
`;

const TextContainer = styled.div`
  margin: 80px;
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
  padding: 18px;
  font-size: 25px;
  letter-spacing: 10px;
  width: fit-content;
  height: fit-content;
  border-radius: 6px;
`;

const Caption = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  text-align: center;
  color: #000;
`;

const Title = styled.span`
  background-color: #111;
  color: #fff;
  padding: 18px;
  font-size: 25px;
  letter-spacing: 10px;
`;

const Img = styled.img`
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
`;

const LandingPage = () => {
  const RouterLink = props => <ReactRouterLink to={props.to} {...props} />;
  return (
    <Container>
      <Img src={bkg} />
      {/* <div>
        <TextContainer>
          <h1>Plan</h1>
          <h1>Excecute</h1>
          <h1>Plan better</h1>
        </TextContainer>
        <TextContainer>
          <h1>We'll get you there - soon</h1>
        </TextContainer>
      </div> */}
      <Caption>
        <Title>The #1 app for atlethes and other people</Title>
      </Caption>

      <Box
        sx={{
          position: 'absolute',
          top: 5,
          right: 5,
        }}
      >
        <Link to="/login" component={RouterLink}>
          <Button color="primary">
            <Title>{lang.nav.login}</Title>
          </Button>
        </Link>
      </Box>
    </Container>
  );
};

export default LandingPage;
