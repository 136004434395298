import { createAction } from 'redux-actions';
import tren from '../../services/tren/tren';

export const setPlan = createAction('PLAN/SET');

export const getAllWeeks = () => dispatch =>
  tren
    .getPlan()
    .then(data => {
      dispatch(setPlan(data.weeks));
    })
    .catch(() => console.warn('failed to get users plan'));
