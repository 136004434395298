import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reciveCallback, resetConnection } from '../../store/actions/connectAction';
import { Redirect, useLocation } from 'react-router-dom';

const OnboardingConnect = () => {
  const location = useLocation();
  const { inited, newService } = useSelector(
    ({ user: { inited } = {}, connect: { newService } }) => ({
      inited,
      newService,
    })
  );
  const dispatch = useDispatch();
  const [done, setDone] = useState(false);
  useEffect(() => {
    if (newService) {
      dispatch(resetConnection());
      setDone(true);
    } else if (inited) {
      if (location && location.search) {
        let regex = /[?&]([^=#]+)=([^&#]*)/g;
        let params = {};

        let match;
        while ((match = regex.exec(location.search))) {
          params[match[1]] = match[2];
        }
        dispatch(reciveCallback(params));
      }
    }
  }, [inited, dispatch, location, newService]);

  return done ? <Redirect to="/onboarding" /> : <div>Loading</div>;
};

export default OnboardingConnect;
