import { handleActions } from 'redux-actions';
import { logInUser, setUserData, setUserStatus } from '../../store/actions/userAction';

const initialState = {
  inited: false,
  status: {
    hasPlan: false,
  },
  phoneNumber: null,
  token: null,
  uid: null,
};

export default handleActions(
  {
    [logInUser]: (state, { payload: { phoneNumber, token, uid } }) => ({
      ...state,
      phoneNumber,
      token,
      uid,
    }),
    [setUserData]: (state, { payload: { connections, status } = {} }) => ({
      ...state,
      connections,
      status: {
        ...status,
      },
      inited: true,
    }),
    [setUserStatus]: (state, { payload }) => ({
      ...state,
      status: {
        ...state.status,
        ...payload,
        inited: true,
      },
    }),
  },
  initialState
);
