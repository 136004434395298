import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import TextFieldWithController from '../textField/TextFieldWithController';
import RadioWithController from '../radioButton/RadioWithController';

const RunningSettingsFields = ({ control, errors, watch }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading, weeklyMilage, vdotPace, inited } = useSelector(
    ({ loader: { loading }, onboarding: { weeklyMilage, vdotPace, inited } }) => ({
      inited,
      loading,
      weeklyMilage,
      vdotPace,
    })
  );

  const vdot = watch('vdot');

  return inited ? (
    <>
      <TextFieldWithController
        name={'weeklyMilage'}
        label={'How far do you run every week?'}
        control={control}
        value={weeklyMilage ?? null}
        // value={}
        rules={{
          type: 'number',
          required: true,
        }}
        errors={errors}
      />
      <RadioWithController
        name={'vdot'}
        label={'We need to know about your current fitness, choose a method:'}
        control={control}
        value={vdotPace ? 'lactate' : null}
        rules={{
          required: true,
        }}
        options={[
          {
            title: 'I know a recent race or can predict a time',
            value: 'race',
          },
          {
            title: 'Lactate threshold pace',
            value: 'lactate',
          },
          {
            title: 'Have no idea!',
            value: 'none',
          },
        ]}
        errors={errors}
      />

      {vdot === 'race' ? (
        <>
          <RadioWithController
            name={'racedistance'}
            label={'Race distance'}
            control={control}
            // value={}
            rules={{
              required: true,
            }}
            options={[
              {
                title: '1500m',
                value: '1500m',
              },
              {
                title: '3000m',
                value: '3000m',
              },
              {
                title: '5K',
                value: '5k',
              },
              {
                title: '10K',
                value: '10k',
              },
              {
                title: 'Half marathon',
                value: 'half',
              },
              {
                title: 'Marathon',
                value: 'marathon',
              },
            ]}
            errors={errors}
          />
          <TextFieldWithController
            name={'racetime'}
            label={'Race time'}
            control={control}
            // value={}
            rules={{
              type: 'string',
              required: true,
            }}
            errors={errors}
          />
        </>
      ) : vdot === 'lactate' || vdotPace ? (
        <TextFieldWithController
          name={'thresholdpace'}
          label={'Threshold pace'}
          control={control}
          value={vdotPace ?? null}
          rules={{
            type: 'string',
            required: true,
          }}
          errors={errors}
        />
      ) : null}
    </>
  ) : null;
};

export default RunningSettingsFields;
