import { handleActions } from 'redux-actions';
import { setPlan } from '../actions/planAction';

const initialState = {
  weeks: [],
};

export default handleActions(
  {
    [setPlan]: (state, { payload }) => ({
      ...state,
      weeks: payload,
    }),
  },
  initialState
);
