import { handleActions } from 'redux-actions';
import { setLoading } from '../actions/loaderAction';

const initialState = {
  loading: false,
};

export default handleActions(
  {
    [setLoading]: (state, { payload }) => ({
      ...state,
      loading: payload,
    }),
  },
  initialState
);
