import React from 'react';
import { useScrollTrigger } from '@material-ui/core';

const ScrollToColor = ({
  threshold = 0,
  bgColorBefore,
  bgColorAfter,
  textColorBefore,
  textColorAfter,
  borderBottomBefore,
  borderBottomAfter,
  fadeIn,
  fadeOut,
  paddingBefore,
  paddingAfter,
  children,
  ...other
}) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: threshold,
    target: other.window ? window() : undefined,
  });

  const style = {};
  if (paddingAfter && paddingBefore) {
    style.paddingTop = trigger ? paddingAfter : paddingBefore;
  }
  if (paddingAfter && paddingBefore) {
    style.paddingBottom = trigger ? paddingAfter : paddingBefore;
  }
  if (bgColorAfter && bgColorBefore) {
    style.backgroundColor = trigger ? bgColorAfter : bgColorBefore;
  }
  if (borderBottomAfter && borderBottomBefore) {
    style.borderBottom = trigger ? borderBottomAfter : borderBottomBefore;
  }
  if (textColorAfter && textColorBefore) {
    style.color = trigger ? textColorAfter : textColorBefore;
  }
  if (fadeIn && fadeOut) {
    style.transition = trigger ? fadeIn : fadeOut;
  }
  return React.cloneElement(children, {
    style,
    ...other,
  });
};

export default ScrollToColor;
