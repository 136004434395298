import { createAction } from 'redux-actions';
import trenAPI from '../../services/tren/tren';
import { setLoading } from './loaderAction';
import { setPlan } from './planAction';
import { setUserStatus } from './userAction';

export const setOnboardingData = createAction('ONBOARDING/SET_DATA');
export const setQuestions = createAction('ONBOARDING/SET_QUESTIONS');
export const questionsSetDone = createAction('ONBOARDING/QUESTIONS_SET_DONE');

export const analyzeBackground = () => async dispatch => {
  await trenAPI.getNewActivities();
  //   const background = await trenAPI.analyzeBackground();
};

export const getOnboardingData = () => async dispatch => {
  const onboardingData = await trenAPI.getOnboardingData();
  if (onboardingData) {
    dispatch(setOnboardingData(onboardingData));
  } else {
    dispatch(setOnboardingData());
  }
};

export const saveOnboardingData = data => dispatch => {
  try {
    trenAPI.postOnboardingData(data);
    dispatch(setOnboardingData(data));
  } catch (err) {}
};

export const saveOnboardingDataAndGenerateNewPlan = data => dispatch => {
  try {
    dispatch(setLoading(true));
    trenAPI
      .postOnboardingData({ ...data, generateNewPlan: true })
      .then(() => {
        dispatch(setOnboardingData(data));
        trenAPI
          .getPlan()
          .then(data => {
            dispatch(setPlan(data.weeks));
            dispatch(setLoading(false));
          })
          .catch(() => console.warn('failed to get users plan'));
      })
      .catch(err => dispatch(setLoading(false)));
  } catch (err) {}
};
export const generatePlan = () => async dispatch => {
  dispatch(setLoading(true));
  const plan = await trenAPI.generatePlan();
  dispatch(setPlan(plan));
  dispatch(setLoading(false));
};
