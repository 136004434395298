
import {strings} from './i18n';
const translate = {
  buttons: {
    Save: strings("buttons.Save"),
    Send: strings("buttons.Send"),
    Cancel: strings("buttons.Cancel"),
    Edit: strings("buttons.Edit"),
    Delete: strings("buttons.Delete"),
    Publish: strings("buttons.Publish")
  },
  article: {
    EditArticles: strings("article.EditArticles"),
    notificationHeader: strings("article.notificationHeader"),
    notificationTitle: strings("article.notificationTitle"),
    pregnantWeek: strings("article.pregnantWeek"),
    parentWeek: strings("article.parentWeek")
  },
  articleEdit: {
    validate_required: strings("articleEdit.validate_required"),
    validate_maxLength: strings("articleEdit.validate_maxLength"),
    validate_minLength: strings("articleEdit.validate_minLength"),
    validate_default: strings("articleEdit.validate_default"),
    fieldName_title: strings("articleEdit.fieldName_title"),
    fieldName_oneliner: strings("articleEdit.fieldName_oneliner"),
    fieldName_summary: strings("articleEdit.fieldName_summary"),
    fieldName_keyTakeaway: strings("articleEdit.fieldName_keyTakeaway"),
    fieldName_body: strings("articleEdit.fieldName_body"),
    Preview: strings("articleEdit.Preview"),
    CreateNewArticle: strings("articleEdit.CreateNewArticle")
  },
  carefeed: {
    weeks: strings("carefeed.weeks"),
    week: strings("carefeed.week"),
    pregnantHeader: strings("carefeed.pregnantHeader"),
    parentHeader: strings("carefeed.parentHeader")
  },
  nav: {
    admin: strings("nav.admin"),
    carefeedPregnancy: strings("nav.carefeedPregnancy"),
    carefeedParents: strings("nav.carefeedParents"),
    choosePortal: strings("nav.choosePortal"),
    login: strings("nav.login"),
    logout: strings("nav.logout")
  },
  portal: {
    addPhoneNumber: strings("portal.addPhoneNumber"),
    addPhoneNumberHeader: strings("portal.addPhoneNumberHeader"),
    admin: strings("portal.admin"),
    admini: strings("portal.admini"),
    surName: strings("portal.surName"),
    name: strings("portal.name"),
    phoneNumber: strings("portal.phoneNumber"),
    editor: strings("portal.editor"),
    editori: strings("portal.editori"),
    enterPhoneNumber: strings("portal.enterPhoneNumber"),
    choosePortal: strings("portal.choosePortal")
  },
  stats: {
    Statistics: strings("stats.Statistics"),
    newUserChartLabel: strings("stats.newUserChartLabel"),
    NewUsersInWeeks: strings("stats.NewUsersInWeeks"),
    Top10Read: strings("stats.Top10Read"),
    Week: strings("stats.Week"),
    ThisWeek: strings("stats.ThisWeek"),
    UserCount: strings("stats.UserCount"),
    weekPercentUp: strings("stats.weekPercentUp"),
    weekPercentDown: strings("stats.weekPercentDown")
  },
  notFound: {
    pageNotFound: strings("notFound.pageNotFound")
  },
  error: {
    noAccesToPhoneNumbersInPortal: strings("error.noAccesToPhoneNumbersInPortal"),
    serverError: strings("error.serverError"),
    phoneNumberIsInvalid: strings("error.phoneNumberIsInvalid")
  }
}

export default translate;