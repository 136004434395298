import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { saveOnboardingData } from '../../store/actions/onboardingAction';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@material-ui/core/Stack';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import PersonaliaFields from '../../components/forms/PersonaliaFields';

const OnbardingPersonaliaQuestions = ({ next }) => {
  const dispatch = useDispatch();
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid, isDirty },
  } = useForm();
  const { loading } = useSelector(({ loader: { loading } }) => ({
    loading,
  }));

  const onSubmit = data => {
    dispatch(
      saveOnboardingData({
        ...data,
        onboardingProgress: 'running',
        userWeight: parseInt(data.userWeight),
        userHeight: parseInt(data.userHeight),
      })
    );
    next();
  };

  return (
    <>
      <>
        <Box
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            '& > :not(style)': { m: 1, width: '55ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <PersonaliaFields control={control} errors={errors} watch={watch} />
        </Box>

        <Stack direction="row" spacing={2}>
          <LoadingButton loading={loading} variant="contained" onClick={handleSubmit(onSubmit)}>
            save changes
          </LoadingButton>
        </Stack>
      </>
      {loading ? (
        <>
          <h3>Analysing your body...</h3>
          <CircularProgress color="secondary" />
        </>
      ) : null}
    </>
  );
};

export default OnbardingPersonaliaQuestions;
