import { handleActions } from 'redux-actions';
import { questionsSetDone, setQuestions, setOnboardingData } from '../actions/onboardingAction';

const initialState = {
  //   questionsSetDone: [],
  //   questionsSets: [],
  //   questions: {},
  //   questionsSetProgression: 0,
  //   setsType: null,
  inited: false,
  onboardingProgress: 'services',
  weeklyMilage: null,
  vdotPace: null,
};

export default handleActions(
  {
    // [setQuestions]: (
    //   state,
    //   { payload: { questionsSetProgression, questions, questionName, type } }
    // ) => ({
    //   ...state,
    //   questionsSetProgression,
    //   questions: {
    //     ...state.questions,
    //     [`${questionsSetProgression}`]: questions,
    //   },
    //   questionsSets: [...state.questionsSets, questionName],
    //   setsType: type,
    // }),
    // [questionsSetDone]: (state, { payload }) => ({
    //   ...state,
    //   questionsSetDone: [...state.questionsSetDone, payload],
    // }),
    [setOnboardingData]: (state, { payload }) => ({
      ...state,
      ...payload,
      inited: true,
    }),
  },
  initialState
);
