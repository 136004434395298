const TREN_URL = process.env.REACT_APP_TREN_API;

export default {
  createStravaToken: {
    method: 'post',
    url: `${TREN_URL}/api/connect/strava/token`,
  },
  saveConnection: {
    method: 'post',
    url: `${TREN_URL}/api/connect`,
  },
  getConnectionServices: {
    method: 'get',
    url: `${TREN_URL}/api/user/connect`,
  },
  postOnboardingData: {
    method: 'post',
    url: `${TREN_URL}/api/onboarding`,
  },
  getOnboardingData: {
    method: 'get',
    url: `${TREN_URL}/api/onboarding`,
  },
  getUserData: {
    method: 'get',
    url: `${TREN_URL}/api/user`,
  },
  postUserData: {
    method: 'post',
    url: `${TREN_URL}/api/user`,
  },
  getAnalytics: {
    method: 'get',
    url: `${TREN_URL}/api/user/analytics`,
  },
  getCurrentWeek: {
    method: 'get',
    url: `${TREN_URL}/api/user/currentweek`,
  },
  getPlan: {
    method: 'get',
    url: `${TREN_URL}/api/plan`,
  },
  postQuestions: {
    method: 'post',
    url: `${TREN_URL}/api/analyze/questions`,
  },
  generatePlan: {
    method: 'get',
    url: `${TREN_URL}/api/plan/createPlan`,
  },
  getNewActivities: {
    method: 'get',
    url: `${TREN_URL}/api/analyze/newActivities`,
  },
  analyzeBackground: {
    method: 'get',
    // url: `${TREN_URL}/api/analyze/analyze`,
    url: `${TREN_URL}/api/analyze/background`,
  },
};
