import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

// reducers
// import { reducer as modal } from 'redux-modal';
import user from './userReducer';
import analyze from './analyzeReducer';
import connect from './connectReducers';
import weekPlan from './weekPlanReducer';
import plan from './planReducer';
import onboarding from './onboardingReducer';
import loader from './loaderReducer';

//middlewares

const rootReducer = combineReducers({
  analyze,
  user,
  connect,
  weekPlan,
  plan,
  onboarding,
  loader,
});

const middleware = [thunk];

let composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = () => {
  return createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)));
};

export default store;
