import { createAction } from 'redux-actions';
import tren from '../../services/tren/tren';
import axios from 'axios';

export const setUserStatus = createAction('USER/STATUS');
export const setUserData = createAction('USER/SET', payload => ({
  ...payload,
  connections: payload?.services.reduce(
    (obj, { service_name }) => ({ ...obj, [service_name]: true }),
    {}
  ),
}));

export const logInUser = createAction('USER/LOGIN_USER', payload => ({
  phoneNumber: payload && payload.phoneNumber,
  token: payload && payload.token,
  uid: payload && payload.uid,
}));

export const saveUserStatus = status => dispatch => {
  tren
    .postUserData(status)
    .then(data => dispatch(setUserData(data)))
    .catch(() => dispatch(setUserStatus({})));
};

export const getUserData = () => dispatch =>
  tren
    .getUserData()
    .then(data => {
      dispatch(setUserData(data));
    })
    .catch(() => dispatch(setUserData(null)));

export const authStateChange = user => async dispatch => {
  if (user) {
    const idTokenResult = await user.getIdTokenResult();
    const token = idTokenResult.token;
    if (token) {
      axios.defaults.headers.common['authtoken'] = token;
      axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
      dispatch(
        logInUser({
          phoneNumber: user.phoneNumber,
          token: idTokenResult.token,
          uid: user.uid,
        })
      );
      return dispatch(getUserData(user.uid));
    }
  } else {
    dispatch(logInUser(null));
  }
};
