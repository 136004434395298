import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import CheckboxButton from '../checkbox/CheckboxButton';
import garminImg from '../../assets/img/connections/garmin-connect.svg';
import stravaImg from '../../assets/img/connections/strava.png';

// import polarImg from '../../assets/img/connections/polar.svg';
// import suunto from '../../assets/img/connections/suunto.svg';
// import apple from '../../assets/img/connections/apple-watch.svg';
// import fitbit from '../../assets/img/connections/fitbit.svg';

const ConnectServicesFields = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { strava, garmin } = useSelector(({ connect: { strava, garmin } }) => ({ strava, garmin }));

  return (
    <>
      <h3>Connect to your activity tracker</h3>
      <p>
        This is for you to get the best expiriance now. There is always an ability to more later
      </p>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={3}>
          <CheckboxButton
            onClick={() => {
              if (!strava) {
                window.location.href = 'http://localhost:8000/connect/strava';
              }
            }}
            img={stravaImg}
            checked={strava}
          />
        </Grid>
        <Grid item xs={3}>
          <CheckboxButton
            onClick={() => {
              if (!garmin) {
                window.location.href = 'http://localhost:8000/connect/garmin';
              }
            }}
            img={garminImg}
            checked={garmin}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ConnectServicesFields;
