import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OnboardingBoxWithProgress from '../../components/OnboardingBoxWithProgress/OnboardingBoxWithProgress';
import TabPanel from '../../components/tabs/TabPanel';
import { getOnboardingData } from '../../store/actions/onboardingAction';
import OnbardingConnectServices from './OnbardingConnectServices';
import OnbardingPersonaliaQuestions from './OnbardingPersonaliaQuestions';
import OnbardingPreview from './OnbardingPreview';
import OnbardingRunningQuestions from './OnbardingRunningQuestions';
import { ONBOARDING_SWITCH } from './OnbardingWizard.constants';

const OnbardingWizard = () => {
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState(null);
  const { completedSteps, inited } = useSelector(
    ({ onboarding: { onboardingProgress, inited } }) => ({
      completedSteps: ONBOARDING_SWITCH?.[onboardingProgress] ?? 0,
      inited,
    })
  );

  useEffect(() => {
    if (inited) {
      setTabIndex(completedSteps);
    }
  }, [inited, completedSteps]);

  useEffect(() => {
    dispatch(getOnboardingData());
  }, []);

  return (
    <OnboardingBoxWithProgress
      step={tabIndex}
      setTabIndex={setTabIndex}
      completedSteps={completedSteps}
    >
      <TabPanel value={tabIndex} index={0}>
        <OnbardingConnectServices next={() => setTabIndex(1)} />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <OnbardingPersonaliaQuestions next={() => setTabIndex(2)} />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <OnbardingRunningQuestions next={() => setTabIndex(3)} />
      </TabPanel>
      <TabPanel value={tabIndex} index={3}>
        <OnbardingPreview />
      </TabPanel>
    </OnboardingBoxWithProgress>
  );
};

export default OnbardingWizard;
