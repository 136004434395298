import { handleActions } from 'redux-actions';
import { resetConnection, setConnection } from '../actions/connectAction';
import { setUserData } from '../actions/userAction';

const initialState = {
  newService: false,
  strava: false,
  garmin: false,
};

export default handleActions(
  {
    [setUserData]: (state, { payload }) => ({
      ...state,
      ...payload.connections,
      newService: true,
    }),
    [setConnection]: (state, { payload }) => ({
      ...state,
      ...payload.connections,
      newService: true,
    }),
    [resetConnection]: state => ({
      ...state,
      newService: false,
    }),
  },
  initialState
);
