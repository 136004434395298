import React from 'react';
import { Controller } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';

const TextFieldWithController = ({ control, name, label, errors, value, rules }) => (
  <FormControl error={errors?.[name]}>
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={value}
      render={({ field }) => (
        <>
          <TextField id={`text-field-${name}`} label={label} variant="outlined" {...field} />
          {!!errors?.[name] ? <FormHelperText>{errors?.[name].message}</FormHelperText> : null}
        </>
      )}
    />
  </FormControl>
);

export default TextFieldWithController;
