import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import {
  getOnboardingData,
  saveOnboardingData,
  saveOnboardingDataAndGenerateNewPlan,
} from '../../store/actions/onboardingAction';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@material-ui/core/Stack';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import RunningSettingsFields from '../../components/forms/RunningSettingsFields';

const RunningSettings = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid, isDirty },
  } = useForm();
  const { loading } = useSelector(({ loader: { loading } }) => ({
    loading,
  }));

  useEffect(() => {
    dispatch(getOnboardingData());
  }, []);

  const onSubmit = data => {
    const userData = {
      weeklyMilage: parseInt(data.weeklyMilage),
      vdot: 58,
      vdotPace: data.thresholdpace ? data.thresholdpace : '03:40',
    };
    dispatch(saveOnboardingDataAndGenerateNewPlan(userData));
  };

  return (
    <>
      <>
        <Box
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            '& > :not(style)': { m: 1, width: '55ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <RunningSettingsFields control={control} errors={errors} watch={watch} />
        </Box>

        {!loading && isDirty ? (
          <Stack direction="row" spacing={2}>
            <LoadingButton loading={loading} variant="contained" onClick={handleSubmit(onSubmit)}>
              Generate new plan
            </LoadingButton>
          </Stack>
        ) : null}
      </>
      {loading ? (
        <>
          <h3>Generating new plan...</h3>
          <CircularProgress color="secondary" />
        </>
      ) : null}
    </>
  );
};

export default RunningSettings;
