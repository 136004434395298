import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, Link as ReactRouterLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { logInUser } from '../../store/actions/userAction';
import { auth } from '../../services/firebase/firebase';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Box from '@mui/material/Box';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import lang from '../../translationWrapper';
import ScrollToColor from './ScrollToColor';

const DashboardNav = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const mapStateToProps = ({ user: { uid } = {} }) => !!uid;
  const loggedIn = useSelector(mapStateToProps);
  const location = useLocation();
  const theme = useTheme();
  const isDesktopView = useMediaQuery(theme.breakpoints.up('lg'));

  const logout = () => {
    auth().signOut();
    dispatch(logInUser(null));
    history.push('/login');
  };

  const isCurrentPage = linkUrl => linkUrl === location.pathname;

  const RouterLink = props => <ReactRouterLink to={props.to} {...props} />;

  return (
    <>
      <ScrollToColor
        bgColorBefore="transparent"
        bgColorAfter={theme.palette.primary.main}
        borderBottomBefore="none"
        borderBottomAfter="1px solid #000"
        textColorBefore="#000"
        textColorAfter="#000"
      >
        <AppBar>
          <Toolbar
            sx={{
              margin: '0 auto',
              /* margin-right: auto; */
              width: '100%',
              //   maxWidth: ${props => theme.breakpoints.values.lgpx`};
              justifyContent: 'space-between',
            }}
          >
            <Toolbar
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
              }}
            >
              <Typography>
                <MainNavigationLink
                  component={RouterLink}
                  to="/dashboard"
                  variant="h6"
                  color="inherit"
                  hover={theme.palette.primary.main}
                >
                  TREN
                </MainNavigationLink>
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '100%',
                }}
              >
                <Typography>
                  <MainNavigationLink
                    component={RouterLink}
                    curentpage={isCurrentPage(`/dashboard`) || isCurrentPage(`/`)}
                    to={`/dashboard`}
                    variant="h6"
                    color="inherit"
                    hover={theme.palette.primary.main}
                  >
                    Calender
                  </MainNavigationLink>
                  <MainNavigationLink
                    component={RouterLink}
                    curentpage={
                      isCurrentPage(`/dashboard/dashboard2`) ||
                      isCurrentPage(`/dashboard/dashboard2`)
                    }
                    to={`/dashboard/dashboard2`}
                    variant="h6"
                    color="inherit"
                    hover={theme.palette.primary.main}
                  >
                    This week
                  </MainNavigationLink>
                  <MainNavigationLink
                    component={RouterLink}
                    curentpage={isCurrentPage(`/dashboard/plan`)}
                    to={`/dashboard/plan`}
                    variant="h6"
                    color="inherit"
                    hover={theme.palette.primary.main}
                  >
                    Overview
                  </MainNavigationLink>
                  <MainNavigationLink
                    component={RouterLink}
                    curentpage={isCurrentPage(`/dashboard/settings`)}
                    to={`/dashboard/settings`}
                    variant="h6"
                    color="inherit"
                    hover={theme.palette.primary.main}
                  >
                    Settings
                  </MainNavigationLink>
                </Typography>
              </Box>
            </Toolbar>
            <Toolbar
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                gap: 30,
              }}
            >
              <Box
                sx={{
                  marginRight: 5,
                }}
              >
                <Button variant="contained" color="primary" onClick={logout}>
                  <Typography variant="h6">{lang.nav.logout}</Typography>
                </Button>
              </Box>
            </Toolbar>
          </Toolbar>
        </AppBar>
      </ScrollToColor>
      <Box
        sx={{
          marginTop: 14,
        }}
      />
    </>
  );
};

export default DashboardNav;

const MainNavigationLink = styled(Link)`
  padding: 0 30px 0 0;
  text-decoration: none;
  ${props =>
    props.hover &&
    css`
      &:hover {
        color: ${props.hover};
      }
    `}
  ${props =>
    props.curentpage &&
    css`
      font-weight: 900;
    `}
`;
